import { useState, useEffect } from "react";
import { fetchInbox } from "datamodel/Inbox/api";
import { getAPIUrl } from "base/shared/Utils";
import envVars from "base/shared/Env";
import fetch from "base/shared/fetch-data";
import { CHATS_PER_PAGE } from "./constants";
import { CURRENT_USER_ROLES } from "app/datamodel/Inbox/constants";

const getFirstRequest = (requests) => ({
  currentRequest: requests[0],
  idOfPreviousRequest: null,
  idOfNextRequest: requests[1]?.id,
});

const findRequestWithId = (requests, id) => {
  if (!id) {
    return;
  }
  const currentRequestIndex = requests.findIndex((request) => request.id == id);
  const currentRequest = requests[currentRequestIndex];
  if (!currentRequest) {
    return;
  }
  return {
    currentRequest,
    idOfPreviousRequest: requests[currentRequestIndex - 1]?.id,
    idOfNextRequest: requests[currentRequestIndex + 1]?.id,
  };
};

const getConnectionEnquiry = async (id, token) => {
  const { BADI_CONNECTION_ENQUIRY } = envVars();
  const serverUrl = getAPIUrl(BADI_CONNECTION_ENQUIRY.replace(":id", id));

  const response = await fetch(serverUrl, {
    token,
  });
  return response.data;
};

const fetchConnectionsAndEnquiries = async ({ token, page }) => {
  const result = await fetchInbox(
    { token },
    {
      page,
      per: 5,
      current_user_role: CURRENT_USER_ROLES.LISTER,
      category: "enquiry_pending",
    },
  );
  const { data, pagination } = result;
  const connections = data.connections;
  const requests = await Promise.all(
    connections.map(async (connection) => {
      const enquiry = await getConnectionEnquiry(connection.id, token);
      return { id: connection.id, connection, enquiry };
    }),
  );
  return {
    data: requests,
    pagination,
  };
};

const countRequestsToTheRightOf = (currentRequest, requests) => {
  const indexOfCurrentRequest = requests.findIndex(
    (request) => request.id == currentRequest.id,
  );
  return requests.length - 1 - indexOfCurrentRequest;
};

const areThereRequestsRemainingToFetch = ({ current_page, total_pages }) =>
  current_page < total_pages;

// this controls how eagerly we fetch further requests
// i.e if there are fewer than x remaining requests to display, we load more.
const EAGER_LOADING_OFFSET = 3;

const DEFAULT_PAGINATION = {
  total: 0,
  current_page: 1,
  total_pages: 1,
};

const fetchDataForRequests = (token, id) => {
  const [data, setData] = useState({
    pagination: DEFAULT_PAGINATION,
    requests: [],
    currentRequest: null,
    idOfPreviousRequest: null,
    idOfNextRequest: null,
  });
  useEffect(() => {
    const requestFromCache = findRequestWithId(data.requests, id);
    if (requestFromCache?.currentRequest) {
      setData({ ...data, ...requestFromCache });
      if (
        countRequestsToTheRightOf(
          requestFromCache.currentRequest,
          data.requests,
        ) < EAGER_LOADING_OFFSET &&
        areThereRequestsRemainingToFetch(data.pagination)
      ) {
        fetchConnectionsAndEnquiries({
          token,
          page: data.pagination.current_page + 1,
        }).then((result) => {
          const requests = [...data.requests, ...result.data];
          const request =
            findRequestWithId(requests, id) || getFirstRequest(requests);
          setData({
            ...data,
            ...request,
            pagination: result.pagination,
            requests,
          });
        });
      }
    } else {
      fetchConnectionsAndEnquiries({ token }).then((result) => {
        const requests = [...data.requests, ...result.data];
        const request =
          findRequestWithId(requests, id) || getFirstRequest(requests);
        setData({
          ...data,
          ...request,
          pagination: result.pagination,
          requests,
        });
      });
    }
  }, [id]);

  return data;
};

const fetchDataForChats = (token, filters, { page }) => {
  const { roomId, category } = filters;
  const [data, setData] = useState({
    pagination: DEFAULT_PAGINATION,
  });
  useEffect(() => {
    fetchInbox(
      { token },
      {
        page: page || 1,
        per: CHATS_PER_PAGE,
        current_user_role: CURRENT_USER_ROLES.LISTER,
        room: roomId,
        category,
      },
    ).then(({ data: { connections }, pagination }) => {
      setData({ data: connections, pagination, isLoaded: true });
    });
  }, [roomId, category, page]);
  return data;
};

export { fetchDataForRequests, fetchDataForChats };
